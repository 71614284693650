import api from '../../services/apiService';

let fetchMembersPromise = null; // Cache the promise to avoid multiple API calls

export default {
    namespaced: true,
    state: {
        memberList: null, // Cached member list data
    },
    mutations: {
        setMemberList(state, members) {
            state.memberList = members;
        },
        clearMemberList(state) {
            state.memberList = null;
        },
    },
    actions: {
        async fetchMembers({ commit, state }, { query = '', limit = 10, page = 1 } = {}) {
            // Return cached data if it exists and no dynamic filtering is requested
            if (state.memberList && !query && !limit) {
                return state.memberList;
            }

            // If no ongoing request, initiate the API call
            if (!fetchMembersPromise) {
                const params = { query, limit, page };

                fetchMembersPromise = api
                    .get('/admin/members/select-members', { params })
                    .then((response) => {
                        if (response.status === 200 && response.data?.data) {
                            const dataArray = Object.values(response.data.data);

                            const members = dataArray.map((member) => ({
                                value: member.uid,
                                tid: member.tid,
                                label: member.username,
                                role: member.role,
                            }));

                            if (!query && !limit) {
                                commit('setMemberList', members);
                            }

                            return members;
                        }
                        return [];
                    })
                    .catch((error) => {
                        console.error('Error fetching members:', error);
                        return [];
                    })
                    .finally(() => {
                        fetchMembersPromise = null; // Reset the promise cache
                    });
            }

            return fetchMembersPromise;
        },

        clearCachedMembers({ commit }) {
            commit('clearMemberList'); // Clear the cached member list
        },
    },
};
