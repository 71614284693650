import AuthService from '../../services/authService';
import TokenService from '../../services/tokenService';

// Function to dynamically initialize user status
const getUserStatus = (type) => {
    const user = TokenService.getUser(type);
    return {
        loggedIn: !!user,
        user: user || null,
    };
};

// Initial state
export const state = () => ({
    adminStatus: getUserStatus('admin'),
    memberStatus: getUserStatus('member'),
});

// Helper function to dynamically access status
const getStatus = (state, type) => (type === 'admin' ? state.adminStatus : state.memberStatus);

export const getters = {
    loggedIn: (state) => (type) => getStatus(state, type).loggedIn,
    isAdminLoggedIn: (state) => state.adminStatus.loggedIn,
    isMemberLoggedIn: (state) => state.memberStatus.loggedIn,
    adminUser: (state) => state.adminStatus.user,
    memberUser: (state) => state.memberStatus.user,
    userType: (state) => {
        if (state.adminStatus.loggedIn && state.adminStatus.user) return state.adminStatus.user.type;
        return null;
    },
    memberType: (state) => {
        if (state.memberStatus.loggedIn && state.memberStatus.user) return state.memberStatus.user.type;
        return null;
    },

};

export const mutations = {
    loginSuccess(state, { user, type }) {
        const status = getStatus(state, type);
        status.loggedIn = true;
        status.user = user.data.user;
    },
    loginFailure(state, type) {
        const status = getStatus(state, type);
        status.loggedIn = false;
        status.user = null;
    },
    logout(state, type) {
        const status = getStatus(state, type);
        status.loggedIn = false;
        status.user = null;
    },
    refreshToken(state, { accessToken, type }) {
        const status = getStatus(state, type);
        if (status.user) {
            status.user = { ...status.user, accessToken };
        }
    },
};

export const actions = {
    init({ dispatch }) {
        dispatch('validate');
    },
    async signup({ commit }, user) {
        return AuthService.signup(user)
            .then((response) => Promise.resolve(response))
            .catch((error) => {
                commit('loginFailure', 'member');
                return Promise.reject(error);
            });
    },
    async performLogin({ commit }, { method, params, type }) {
        return method(params)
            .then((response) => {
                commit('loginSuccess', { user: response, type });
                return Promise.resolve(response);
            })
            .catch((error) => {
                commit('loginFailure', type);
                return Promise.reject(error);
            });
    },    
    async autologin({ dispatch }, { token, type = 'member' }) {
        return dispatch('performLogin', { method: AuthService.autologin, params: { token, type }, type, });
    },

    async login({ dispatch }, { username, password, remember, type = 'member' }) {
        return dispatch('performLogin', { method: AuthService.login, params: { username, password, remember, type }, type, });
    },
    async logout({ commit }, type) {
        return AuthService.logout(type)
            .then((response) => {
                commit('logout', type);
                return Promise.resolve(response);
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    TokenService.removeUser(type);
                    return Promise.reject(error.response.data);
                }
                return Promise.reject(error);
            });
    },
    async validate({ state }, type) {
        const status = getStatus(state, type).loggedIn;
        return status ? TokenService.getUser(type) : Promise.resolve(null);
    },
    refreshToken({ commit }, { accessToken, type }) {
        commit('refreshToken', { accessToken, type });
    },
};
