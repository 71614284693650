import api from '../../services/apiService';

let fetchPromise = null; // Cache the promise to avoid multiple API calls

export default {
    namespaced: true,
    state: {
        managers: null, // Cached managers data
    },
    mutations: {
        setManagers(state, managers) {
            state.managers = managers;
        },
    },
    actions: {
        async fetchManagers({ commit, state }) {
            // Return cached data if it exists
            if (state.managers) {
                return state.managers;
            }

            // If no ongoing request, initiate the API call
            if (!fetchPromise) {
                fetchPromise = api
                    .get('/admin/managers/select-data')
                    .then((response) => {
                        if (response.status === 200 && Array.isArray(response.data?.data)) {
                            const managers = response.data.data.map((manager) => ({
                                value: manager.manager_id,
                                label: manager.username,
                            }));
                            commit('setManagers', managers); // Cache the managers in Vuex
                            return managers;
                        }
                        return [];
                    })
                    .catch((error) => {
                        console.error('Error fetching managers:', error);
                        return [];
                    })
                    .finally(() => {
                        fetchPromise = null; // Reset the promise cache
                    });
            }

            return fetchPromise;
        },
    },
};
